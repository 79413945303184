<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>

          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级审核详情</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员分组</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <transfer ref="transfer" :studentLists="studentList" :studentGroups="studentGroup"></transfer>
            <div class="flexjc btn_box" style="padding-top:35px">
              <el-button class="bgc-bv" round @click="docencal">取消</el-button>
              <el-button class="bgc-bv" round @click="dosave">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import transfer from "@/components/transfer.vue";
export default {
  name: "sjzRegulator/studentGroup",
  components: {
    transfer
  },
  data() {
    return {
      studentList: [], // 未分组的学员列表
      studentGroup: [
        {
          G_item: []
        }
      ], // 分的组
      open: true, // 组件
      projectId: this.$route.query.projectId
    };
  },
  created() {
    this.getstudentList();
    this.getstudentGroupDetail();
  },
  computed: {},
  methods: {
    // 获取学员列表
    getstudentList() {
      this.$post("/biz/roll/group/queryNotGroupProjectUser", {
        projectId: this.$route.query.projectId
      }).then(res => {
        if (res.status == 0) {
          const data = res.data || [];
          this.studentList = data;
        }
      });
    },
    // 获取分组的列表
    getstudentGroupDetail() {
      this.$post("/biz/roll/group/queryGroupDetail", {
        projectId: this.$route.query.projectId
      }).then(res => {
        if (res.status == 0) {
          const data = res.data || [];
          const list = data.map(el => {
            return {
              groupId: el.groupId || null,
              G_item: el.groupUserBOS || []
            };
          });
          this.studentGroup = list;
          // this.open = true;
        }
      });
    },
    // 确定
    dosave() {
      this.$refs.transfer.dosave(
        "/web/SupervisionEnd/classCheckDetail",
        JSON.parse(this.$route.query.oldData)
      );
    },
    // 取消

    docencal() {
      let data = JSON.parse(this.$route.query.oldData);
      this.$router.push({
        path: "/web/SupervisionEnd/classCheckDetail",
        query: {
          ...data
        }
      });
    }
  }
};
</script>
<style lang="less">
.btn_box {
  padding: 40px 0 0;
}
</style>
